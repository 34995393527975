import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

const Index: React.FC = () => {
  useEffect(() => {
    navigate('/arcade');
  }, []);
  return null;
};

export default Index;
